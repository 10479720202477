@import 'propertyMixins';

/*------------------------------------*\
  #TYPOGRAPHY
\*------------------------------------*/

// Font families
$font-family-sans   : "Source Sans Pro", Helvetica, Arial, sans-serif !default;
$font-family-sans-secondary: "Montserrat", Helvetica, Arial, sans-serif !default;
$font-family-serif  : Times, serif !default;
$font-family-cursive: cursive !default;

// Font families --GD
$font-family-barlow   : "Barlow", sans-serif !default;
$font-family-playfair : "Playfair Display", serif !default;
$font-family-lora : "Lora", serif !default;
$font-family-roboto: "Roboto", sans-serif !default;
$font-family-crimson: "Crimson Pro", serif !default;

$heading-type-font-family: $font-family-playfair !default;
$heading-type-font-weight: 400 !default;
$heading-type-letter-spacing: normal !default;

$body-font-family: $font-family-barlow !default;

// Larger than headings
$font-size-yotta  : 8.875rem !default;  // 142px
$font-size-zetta  : 7.375rem !default;  // 118px
$font-size-exa    : 6.25rem !default;   // 100px

// Headings
$font-size-h1      : 5.125rem !default;  // 82px
$font-size-h2      : 4.375rem !default;  // 70px
$font-size-h3      : 3.5rem !default;    // 56px
$font-size-h4      : 3rem !default;      // 48px
$font-size-h5      : 2.5rem !default;    // 40px
$font-size-h6      : 2rem !default;      // 32px

// Body font sizes
$font-size-mega    : 2.25rem !default; //36px
$font-size-kilo    : 1.75rem !default; //28px
$font-size-hecto   : 1.5rem !default; //24px
$font-size-21      : 1.325 !default; // 21px
$font-size-deka    : 1.25rem !default; //20px

$font-size-medium  : 1.125rem !default; // 18px
$font-size-base    : 1rem !default; // 16px Base font size

$font-size-deci    : .875rem !default; // 14px
$font-size-centi   : .75rem !default; // 12px
$font-size-micro   : .625rem !default; // 10px
$font-size-deci-mobile : .875rem !default; // 14px


// Font Weights
$font-weight-light    : 300 !default;
$font-weight-normal   : 400 !default;
$font-weight-lightbold: 500 !default;
$font-weight-semibold : 600 !default;
$font-weight-bold     : 700 !default;
$font-weight-extrabold: 800 !default;

// Font Line Height

$font-line-height-mega  : 1.9 !default;
$font-line-height-kilo  : 1.75 !default;
$font-line-height-hecto : 1.63 !default;
$font-line-height-deka  : 1.5 !default;
$font-line-height-base  : 1.38 !default;
$font-line-height-deci  : 1.19 !default;
$font-line-height-centi : 1.06 !default;
$font-line-height-milli : 0.85 !default;

/*------------------------------------*\
  #COLORS
\*------------------------------------*/

$color-white: #FFF !default;
$color-black: #231f20 !default;
$color-black-1: #191919 !default;
$color-black-2: #3a3a3a !default;
$color-sky: #52ffdd !default;
$color-link-blue: #0062FF !default;

//GREYSCALE COLORS
$color-grey-1: #F6F6F8 !default;
$color-grey-2: #E6E9ED !default;
$color-grey-3: #CCCCCC !default;
$color-grey-4: #999999 !default;
$color-grey-5: #666666 !default;
$color-grey-6: #444444 !default;
$color-grey-7: #252525 !default;
$color-grey-8: #333 !default;
$color-grey-9: #D8D8D8 !default;
$color-grey-10: #fafaf9 !default;
$color-grey-11: #222b45 !default;
$color-grey-12: #585858 !default;
$color-grey-13: #2c414c !default;
$color-grey-14: #e9e9e9 !default;
$color-grey-15: #27251f !default;
$color-grey-16: #41485f !default;
$color-grey-17: #F9F9F9 !default;
$color-grey-18:	#222222 !default;
$color-grey:    #f6f6f6 !default;
$color-pale-grey:#fbfbfb !default;
$color-grey-medium: #b5b5b6 !default;
$color-grey-dark: #727171 !default;
$color-grey-border: #888 !default;
$color-login-otp: #1E1E1E !default;
$form-input-disabled: #F6F6F8 !default;
$form-input-disabled-border: #E6E9ED !default;
$color-light-gray:#908C88 !default;
$color-grey-19:#EFEDE6 !default;
$color-grey-20:#cacaca !default;
$color-grey-21: #696969 !default;
$color-grey-22: #808080 !default;
$color-grey-23: #e6e1e1 !default;
$color-grey-24: #a9a9a9 !default;
$color-grey-25: #a99e96 !default;
$color-laylty-yellow: #EBC755 !default;
//AD COLORS
$color-dark-green:  #1e381e !default;
$color-dark-gold:  #b18e35 !default;
$color-cream: #FAF8F0 !default;
$colo-cream-1: #F7F3E7 !default;
$color-forest: #1E381E !default;
$color-mid-heading: #242424 !default;
//GD COLORS
$color-header:  #ffcc00 !default;
$color-sunglow: #ffcc00 !default;
$color-light-yellow:#f7e08e !default;
$color-medium-yellow:#ffc507 !default;
$color-cornsilk:#fce689 !default;
$color-dark-black:  #231f20 !default;
$color-light-black:#1e1e1e !default;
$color-black-1: #41404e !default; 
$color-dark-red:   #e30000 !default;
$color-light-pink:  #ff5a5a !default;
$color-light-red:   #dd0000 !default;
$color-light-champagne: #F3E2CB !default;
$color-heading-black:#1E1E19 !default;
$color-footer-black: $color-grey-7 !default;
$color-footer-discover: #B5B5B5 !default;
$color-light-orange: #fed5c2 !default;
$color-login: $color-white;
$color-loyalty-text: $color-light-black !default;
$color-placeholder-text : #908C88 !default;
$color-danger: #E14B2A;
$color-cart-bg: #F8F8F8 !default;
$color-red: #e60000 !default;
$color-red-1: #ff5252 !default;
$color-blue: #4D96E7 !default;
$color-dark-blue: #004994 !default;
$color-green: #00D3A7 !default;
$color-yellow: #E7B04D !default;
$color-beige: #f5f5dc !default;
$color-dropdown-bg: #F5F5F5 !default;
$color-login-banner: #f1ede7 !default;

$color-ivory: #F2E3D3!default;
$color-pink: #ff787d!default;
$color-pink-light: #fff0f1!default;
$color-filter-border: #DCDDDD !default;
$color-rewards-yellow: #EBC755 !default;
$color-tc-gray: #D9D9D9 !default;
$color-philosphy-grey: #F9F6F0 !default;

//SOCIAL MEDIA COLORS
$color-facebook-blue: #1877f2 !default;
$color-google-blue: #4285f1 !default;
$color-google-red: #ea4236 !default;
$color-instagram-pink: #C62A81 !default;
$color-instagram-orange: #F06430 !default;

$color-paypal-blue: #009cde !default;
$color-paypal-silver: #eeeeee !default;
$color-loyalty-bg: #F4F1EC !default;
// Active colors - PDP, PLP and etc
$color-active: $color-pink !default;
$arrow-gray-color: #131313!default;
//Bg Colors
$color-bg-primary: $color-sunglow !default;
$color-bg-primary-text: $color-black !default;
$color-bg-secondary: $color-light-champagne !default;
$color-bg-login: $color-cornsilk !default;
$gift-card-imgbg: #ffacb1 !default;

//button related colors
$color-primary: #000 !default;
$color-secondary: #fff !default;
$color-tertiary: #ff1c60 !default;
$color-button-tertiary: #d68475 !default;

$color-accent-primary: #0DFFCB !default;
$color-accent-secondary: #DAFF79 !default;
$color-accent-tertiary: #28BAFF !default;

$button-primary-bg: $color-black !default;

$color-custom:#8f77b1 !default;

$select-option-hover: #fcfcfc;

//Set an empty default map, which is then used as a merge point in the case there are overrides in overlaid cartridges.
$colors: () !default;

$subscription-bg: $color-white !default;
$popular-search-bold: $color-black !default;
$popular-search: $color-black !default;
$footer-discover-anchor: $color-sunglow !default;
$discover-heading: $subscription-bg !default;

$colors: map-merge((
    white: $color-white,
    black: $color-black,

    //GREYSCALE COLORS
    grey-1: $color-grey-1,
    grey-2: $color-grey-2,
    grey-3: $color-grey-3,
    grey-4: $color-grey-4,
    grey-5: $color-grey-5,
    grey-6: $color-grey-6,
    grey-7: $color-grey-7,

    red: $color-red,
    blue: $color-blue,
    navy: darken($color-blue, 35%),
    green: $color-green,
    beige: $color-beige,

    //SOCIAL MEDIA COLORS
    facebook-blue: $color-facebook-blue,
    google-blue: $color-google-blue,
    google-red: $color-google-red,
    instagram-pink: $color-instagram-pink,
    instagram-orange: $color-instagram-orange,

    paypal-blue: $color-paypal-blue,
    paypal-silver: $color-paypal-silver,

    bg-primary: $color-bg-primary,
    bg-secondary: $color-bg-secondary,

    primary: $color-primary,
    secondary: $color-secondary,
    tertiary: $color-tertiary,

    accent-primary: $color-accent-primary,
    accent-secondary: $color-accent-secondary,
    accent-tertiary: $color-accent-tertiary,

    error: $color-red,
    success: $color-green
), $colors);


$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
);

//UI COLORS
$color-error: $color-red !default;
$color-success: $color-green !default;
$color-light-green: #238A3E !default;

$color-body-copy: $color-grey-7 !default;
$color-body-background: $color-white !default;
$color-panel-background: $color-body-background !default;
$color-input-background: transparent !default;

/*------------------------------------*\
  #ELEMENT-DEFAULTS
\*------------------------------------*/
$input-border-radius: .125rem !default;
$select-border-radius: .125rem !default;
$input-border-color: $color-grey-4 !default;
$input-border-color-width: .0625rem !default;
$input-radio-color: $color-black !default;
$input-checkbox-color : $color-black !default;

/*------------------------------------*\
  #HEADER-DEFAULTS
\*------------------------------------*/
$header-nav-bg: var(--header-nav-bg, $color-body-background) !default;
$header-nav-color: var(--header-nav-color, 'currentColor') !default;
$header-nav-panel-bg: var(--header-nav-panel-bg, $color-body-background) !default;
$header-nav-panel-color: var(--header-nav-panel-color, 'currentColor') !default;

$header-flyout-level-1-font-weight: 500 !default;

// Logo
$header-logo-width--medium-up: 5.625rem !default;
$header-logo-height--medium-up: 1.875rem !default;
$header-logo-width--small: $header-logo-height--medium-up !default;
$header-logo-height--small: $header-logo-height--medium-up !default;

/*------------------------------------*\
  #HORIZONTAL-SPACING
\*------------------------------------*/

$gutter-inner: .625rem !default; //base
$gutter-inner-deci: .35rem !default;

$gutter-outer-kilo: 3.125rem !default;
$gutter-outer: 2rem !default; //base
$gutter-outer-deci: 1.25rem !default;
$gutter-outer-centi: 1rem !default;
$gutter-outer-micro: .75rem !default;

/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/

$breakpoints: () !default;

// **** If this is updated, make sure FEDConstants.js is also updated. ***
$breakpoints: map-merge((
    unit           : 0.0625rem, // 1
    smallest       : 20rem, // 320
    xsmallest      : 30rem, // 480
    small          : 47.9375rem, // 767
    xsmall         : 56rem, // 896
    medium         : 64rem, // 1,024
    xmedium        : 77.5rem, // 1,240
    large          : 90rem, // 1,440
    xlarge         : 102.5rem, // 1,640
    xxlarge         : 112.5rem, // 1,800
    xxxlarge       : 159.5rem, // 2,552
    custom-tab     : 992px
), $breakpoints);


/*------------------------------------*\
  #TRANSITIONS
\*------------------------------------*/

// Transition durations
$transition-speed-slower    :   1s !default;
$transition-speed-slow      :   600ms !default;
$transition-speed-mid-slow  :   500ms !default;
$transition-speed-normal    :   426ms !default;
$transition-speed-medium    :   320ms !default;
$transition-speed-fast      :   213ms !default;
$transition-speed-faster    :   107ms !default;
$transition-speed-fastest   :   54ms !default;

// Transition easing
$transition-ease-default    :   ease-in-out !default;
$transition-ease-in         :   ease-in !default;
$transition-ease-out        :   ease-out !default;
$transition-ease-bezier     :   cubic-bezier(.4,.9,.3,1) !default;
$transition-ease-bounce     :   cubic-bezier(.4,.9,.3,1.25) !default;
$transition-ease-big-bounce :   cubic-bezier(.4,.9,.3,2.25) !default;


$button-letter-spacing:            0 !default;
$button-primary-color-hover:       $color-black !default;
$button-primary-bg-color:          $color-black !default;
$button-primary-bg-color-hover:    $color-sunglow !default;
$button-font-family:               $font-family-barlow !default;
$button-font-size:                 $font-size-base !default;
$button-padding:                   .75em 2em !default;
$button-border-radius:             4px !default;
$button-secondary-bg:              $color-secondary !default;
$button-secondary-bg-hover:        $color-secondary !default;
$button-secondary-border:          $color-primary !default;
$button-secondary-color:           $color-primary !default;
$button-secondary-color-hover:     $color-primary !default;
$button-secondary-arrow:           none !default;
$color-disclaimer-grey: #252525 !default;
$color-form-placeholer: #A5A5A5 !default;
$color-selected-filter: #F3EFE5 !default;



$form-control-left-padding          :   1.250rem !default;
$form-control-height                :   2.5rem !default;
$form-control-focus-border-color    :   $color-black !default;

$form-control-label-color                    :  #999999 !default;
$form-control-label-foucs-color              :  $color-black !default;
$form-control-label-error-color              :  $color-error !default;
$form-control-label-bg-color                 :  $color-white !default;
$form-control-label-padding                  :  0 .75rem 0 .75rem !default;
$form-control-label-left-position            :  .5rem !default;
$form-control-label-foucs-top-position       :  -1.25rem !default;
$form-control-label-foucs-font-size          :  $font-size-centi !default;

$content-tile-cta-mobile-width               : 100% !default;

$subscription-banner-bg:           $color-sunglow !default;
$subscription-banner-form-color:   $color-black !default;
$footer-logo-popular:           $color-sunglow !default;

$footer-secondary-bg: $color-grey !default;

$link-font-weight:                  normal !default;
$link-letter-spacing:               0 !default;
$link-primary-icon-show:            none !default;
$link-primary-icon-color-hover:     $color-sunglow !default;
$link-primary-border-show:          block !default;
$link-primary-border-color:         $color-sunglow !default;
$link-decoration-underline:         none !default;

$form-control-input-autofill-color           :  $color-black !default;

$price-font-weight:         normal !default;
$price-letter-spacing:      0 !default;
$price-original-color:      $color-grey-5 !default;

$product-promotions-letter-spacing:     0 !default;
$swatches-border-color:                 $color-grey-3 !default;
$dropdown-selected-bg:                  $color-grey !default;
$pill-bg-color:                         $color-light-yellow !default;
$gift-card-bg:                          $color-cornsilk !default;
$header-menu-active:                    $color-black !default;
$login-bg:                              $color-light-yellow !default;
$flyout-menu-border:                    $color-grey-4 !default;
