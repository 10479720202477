
@import "../../helpers/variables";

[data-siteid="AND"] {
.login__section .form-intro__title{
    font-family: $font-family-sans-secondary;
}
.login__section {
    max-width: 27.5rem;
    .login_section--body {
        a.oauth-google, 
        a.oauth-facebook {
            &:hover {
                &.button {
                    background-color:  $color-grey-5;
                    border-color: $color-grey-5;
                }
            }
        }
    }
}

.login-banner {
    margin-bottom: 1.25rem;
    text-align: center;
     &__heading {
         padding-bottom: 0.25rem;
         color: $color-grey-11;
         font-size: 1.5rem;
     }
     &__subHeading {
        color: $color-grey-11;
        font-size: 0.875rem;
    }
}

[data-querystring="cOrder=1"] .main{
    background-color: transparent;
}

.login__section--withOrder {
    .login-banner {
        display: none;
    }
    .login__section--order {
        margin-right: 4rem;
    }
}
.change-password__form {
	.success-msg {
		background: $color-pink-light;
		padding: 7px 50px;
	    font-size: 0.813rem;
	    margin: 30px auto;
	    border-radius: 2px;
	    font-weight: 400;
	    @include media-query(small-up) {
	    	padding: 7px 60px;
	    }
	}
}

.login_box_shadow{
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);
}

.login__section {
    max-width: 27.5rem;
    padding: 0;
    background-color: $color-white;
    &--newPassword{
        margin: 0 auto;
        padding: 1.25rem;

        .heading-type {
            color: $color-grey-8;
            font-size: 1.125rem;
            font-weight: 500;

            &.form-intro__title {
                margin-bottom: 3.75rem;
            }
        }

        .password-resetpassword {
            margin-bottom: 3.75rem;
        }

        .login-password--show {
            font-size: 0.875rem;
            color: $color-light-black;
        }

        .form-control-label {
            &::after {
                display: none;
            }
        }
    }
    .login-promotion {
        img {
            width: 100%;
        }
    }
    h2.form-intro__title {
        font-size: 1rem;
        color: $color-grey-8;
    }
    p.form-intro__title {
        font-size: 0.875rem;
        color: $color-grey-5;
        line-height: 1.375rem;
        font-weight: 400;
        margin-bottom: 0;
        &.login-title-bottom {
        	margin-top: 30px;
        }
    }
    .input_indent {
        text-indent: 2.5rem;
        padding-left: 2.7rem;
    }
    p.form-intro__copy{
        font-size: 0.813rem;
    }
    .oauth-facebook {
        padding-left: .6em;
        padding-right: .6em;
    }
    .oauth-google, 
    .oauth-facebook {
        border-radius: 2px;
        border: 0;
        font-size: 0.875rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);
        span {
            color: $color-grey-5;
            padding-top: 3px;
        }
        &:hover {
        	&.button {
	        	background-color:  $button-primary-bg-color-hover;
	        	border-color:  $button-primary-bg-color-hover;
        	}
        	span {
        		color: $color-white;
        	}
        }
        svg { 
            height: 1.375rem;
            min-width: 18px;
        }
    }
    .captcha-text-container .captcha-text {
        color: $color-grey-5;
        font-size: 0.75rem;
        line-height: 1.25rem;
    }
    .reset-success-msg{
        margin: 6rem auto;
        font-size: 1.5rem;
        color: $color-grey-11;
        padding-left: 3.125rem;
    }

    .send-register-otp-button {
    	color: $color-login-otp;
    	border: 1px solid $color-login-otp;
    	border-radius: 3px;
    	background: $color-white;
    	font-weight: 500;
    	font-family: $font-family-sans-secondary;
    	font-size: 0.875rem;
    	padding: 4px 0;
    	max-width: 122px;
    }
}

.phoneCodeSelect{
    width: 3rem;
    position: absolute;
    left: 0;
    padding-left: 8px;
    border: 0;
}

.login-verify-form {
    .form-control-label {
        padding-top: 0.25rem;
    }
}

.login_section--body,
.login-header {
    padding: 0 1.25rem;
    .password-links,
    .otp-links,
    .form-check {
        padding-bottom: 1.25rem;
    }
    
    @include media-query(small) {
        input {
            font-size: 1rem;
        }
    }
}

.modal--login.modal--auth {
    .window-modal__content {
        padding: 0 0 2rem 0;
        background-color: $color-white;
    }
    .login__section {
        max-width: 100%;
    }
    .login_section--body,
    .login-header {
        padding: 0 1.25rem;
    }
    .login-confirm-back {
        padding: 2rem 1.25rem;
        display: block;
    }
}

.login.reset--password {
    padding: 1.25rem;

    .password-reset-back {
        margin-bottom: 2.063rem;
        display: block;
        display: flex;
        svg {
            margin-top: 0.125rem;
        }
    }
}

.login--message {
    background-color: $color-sky;
    padding: 1.5rem 1.25rem;
    font-size: 0.75rem;
    margin-bottom: 1.25rem;
    color: $color-light-black;
}

.login-header {
    h4 {
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.313rem;
        color: $color-grey-8;
    }
}
.registration-container {
    padding: 1.25rem;
    position: relative;
    .registration-toast-msg {
        background-color: $color-pink-light;
        font-size: 0.813rem;
        padding: 0.563rem 0;
        text-align: center;
        line-height: 1.125rem;
        font-weight: 600;
        position: static;
        z-index: 1;
        margin-bottom: 1.375rem;
    }
    button.verify-register-otp-button[disabled]{
        background-color: transparent;
        cursor: not-allowed;
        color: $color-light-black;
        opacity: 0.5;
    }
    .input_country--code {
        padding-right: 0;
    }
   
    .input_country--phone {
        padding-left: 0;
        input {
            border-left: 1px solid $color-white;
        }
    }
    .password-div {
        padding-top: 2rem;
        ul {
            padding: 0;
            li {
                list-style-position: inside;
                padding-right: 5px;
            }
        }
    }
    .send-otp-section {
        .registered-user-section {
            padding-top: 1.5rem;
            font-size: 0.875rem;
        }
    }
    .input_country--code {
        input {
            position: absolute;
            border-right: 1px solid $color-white;
            width: 2.5rem;
        }
    }
}
.registration-header {
    h4 {
        font-size: 1rem;
        padding-bottom: 0.625rem;
    }
    .heading-message {
        color: $color-grey-5;
        font-size: 0.875rem;
        margin-bottom: 1.875rem;
    }
}

.custom-registration {
    [data-floating-label].form-group {
        .form-control-phone {
            background-color: $color-white;
            position: absolute;
            z-index: 1;
            color: $form-control-label-color;
        }
        .for-control__birthday {
            background-color: $color-white;
            width: 100px;
        }
        .input_country--phone {
            padding-left: 0;
            input {
                border-left: 1px solid $color-white;
                margin-left: 1.25rem;
                padding-left: 0;
            }
        }
        .input_country--code {
            input {
                position: absolute;
                border-right: 1px solid $color-white;
                width: 2.875rem;
            }
        }
    }
    .form_row {
         padding-bottom: 1.25rem;
         .formField_firstName {
             padding-right: 0.5rem;
         }
         .formField_lastName {
            padding-left: 0.5rem;
             label{
                margin-left: 0.5rem;
             } 
        }
        &.otp_section {
            justify-content: flex-end;
            padding-bottom: 0;
            .align-item__right {
                margin-left: auto;
            }
            .verify-register-otp-button {
                padding-top: 0.313rem;
                padding-bottom: 0.313rem;
                margin-top: 0.2rem;
                @include media-query(small) {
                    display: flex;
                    justify-content: center;
                }
            }
        }
        
    }
    .otp_container {
        font-size: 0.875rem;
        color: $color-light-black;
        font-weight: 500;
        span.otp_timer {
            border-left: 1px solid $color-grey-4;
            margin-left: 0.25rem;
            padding-left: 0.25rem;
        }
        .disabled {
            pointer-events: none;
            color: $color-grey-4;
        }
    }
    .shareyour_birthday {
        padding-top: 2.5rem;
        .form-group {
            width: 100%;
        }
        p {
            font-size: 0.875rem;
            padding-bottom: 0;
        }
    }
    .form-check {
        label {
            font-size: 0.75rem;
        }
    }
    .formField_change {
        text-align: right;
        font-size: 0.875rem;
        color: $color-light-black;
        justify-content: center;
    }
    .no-border {
        border: 0;
    }
    .disable_color {
        opacity: 0.5;
    }
    .otp-heading__text {
        color: $color-grey-5;
        font-size: 0.875rem;
        padding-bottom: 1.25rem;
    }
    .form-actions {
        width: 100%;
        margin-top: 0.625rem;
    }
}

.link_text.use-password-link,
.link_text.resend-otp-link,
.reset-otp-counter,
.link_text.forgot-password-link,
.link_text.verify-via-otp-link {
    color: $color-light-black;
    text-decoration: none;
    font-size: 0.875rem;
}
.verify-via-otp-link,
.link_text.resend-otp-link,
.reset-otp-counter {
    text-align: right;
    span {
        padding-left: 0.313rem;
        margin-left: 0.313rem;
        color: $color-grey-16;
        min-width: 3.625rem;
        display: inline-block;

        &.reset-otp-label {
            color: black;
            font-weight: 500;
        }
    }
}
.link_text.resend-otp-link.disabled {
    color:$color-grey-4;
    pointer-events: none;
}
.login-password-input {
    position: relative;
    .invalid-feedback {
        a.forgot-password-link { 
            text-decoration: underline;
        }
    }
}
.login-password--show {
    position: absolute;
    right: 0.625rem;
    top: 0.688rem;
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    width: 2.5rem;
    height: 2rem;
}
.edit-section,.message-otp-display,.message-password-display,.user-login-info {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.313rem;
    margin-bottom: 2rem;

    .link_text {
        font-weight: 600;
        text-decoration: underline;
        color: $color-light-black;
    }
}

.login--wishlist {
    .edit-section {
        margin-bottom: 0;
    }
}
.modal--login,.login,
#modal-checkoutLogin {
    .window-modal__content,
    .login__section {
        .edit-section,
        .user-login-info,
        .message-otp-display  {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
.modal--login,.login,
#modal-checkoutLogin {
    .window-modal__content,
    .login__section {
        .user-login-info  {
            margin-bottom: 2rem;
        }
    }
}
.login-confirm-back {
    padding: 2rem 1.25rem;
}
.registration-back {
    padding-bottom: 0.438rem;
    display: inline-block;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: $color-grey-8;
}
.reset-password-form {
    h4 {
        color: $color-grey-8;
    }
    .modal__description {
        color: $color-grey-5;
        padding-bottom: 2.375rem;
        font-weight: 500;
    }
    .phoneCodeSelect {
        padding-top: 0.125rem;
    }
}

@include media-query(small-up) {
    .login {
        padding-top: 2.125rem;
        padding-bottom: 8rem;
    }
}

@include media-query(small) {
    .login__section {
        .captcha-text-container {
            .captcha-text {
                text-align: center;
                a {
                    font-weight: 500;
                    color: #333;
                }
            }
        }
    }    
    .login__section {
        .oauth-google {
            padding: 0.8125em 0;
        }
        .oauth-facebook {
            padding: 0.8125em 0;
        }
    }    
    .change-password-form .password--change {
        li {
            &:nth-child(2) {
                flex: 0 0 130px;
            }
            &:nth-child(4) {
                flex: 0 0 130px;
            }
        }
    }
    .login {
        padding-top: 2.75rem;
        padding-bottom: 5rem;
    }

    .login__section {
        &:not(:last-child) {
            margin-bottom: 4.5rem;
        }
    }
    .login__section {
        max-width: 100%;
    }
    .login__section--withOrder {
        .login__section--order {
            margin-right: 0;
        }
    }
}

.phoneCodeSelect {
    background: url(../images/drop-down-arrow.png);
    background-size: 6px;
    background-repeat: no-repeat;
    background-position:2.5rem;
}
.loginField {
    padding-top: 0.25rem;
}
.loginField {
    .input_indent {
        padding-left: 2.7rem;
    }
}
.window-modal__content {
    .login-header {
       h4 {
           font-weight: bold;
           margin-top: 1.25rem;
       } 
    }
    .edit-section {
        margin-top: 1.25rem;
    }
}
.change-password-form {
	span.login-password--show {
		font-size: 0;
	}
}
.password--change {
	li {
		&:nth-child(2) {
			flex: 0 0 184px;
		}
		&:nth-child(4) {
			flex: 0 0 184px;
		}
	}
}
.login-mobil{
    font-weight: 600;
}
.signup-mobil {
    font-weight: 600;
}
.login-verify-form .phoneCodeSelect {
    width: 5rem;
    background-position: 3.8rem;
    @include media-query(small) {
        background-position: 4.3rem;
    }
}
.login__section {
	.reset-password-form {
		.input_indent {
			padding-left: 1rem;
		}
	}
}

.trackOrderField {
    .phoneCodeSelect {
        background-position: 4.1rem;
        padding-left: 3px;
        width: 5rem;
    }
}
}